'use client'
import { usePostHog } from 'posthog-js/react'
import { useCallback, useState } from 'react'
import { useDebounceCallback } from 'usehooks-ts'

export type UserIdentifyProps = {
  id: string
  email: string
}

export const useIdentifyUser = () => {
  const postHog = usePostHog()
  const [identifiedEmail, setIdentifiedEmail] = useState<string | null>(null)

  const identify = useCallback(
    ({ email, id }: UserIdentifyProps) => {
      if (email && id && email !== identifiedEmail) {
        try {
          postHog.identify(id, { email, id })
          setIdentifiedEmail(email)
        } catch (_error) {
          // do nothing
        }
      }
    },
    [identifiedEmail, postHog],
  )

  const debouncedIdentify = useDebounceCallback(identify, 2000)

  return { identifyUser: debouncedIdentify }
}
