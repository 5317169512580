export const COUNTRY_CODE_KEY = 'countryCode'
export const QR_CODE_ID_KEY = 'qrCodeId'
export const SITE_SLUG_KEY = 'siteSlug'
export const SITE_SLUG_PREVIEW_KEY = 'siteSlugPreview'
export const PAGE_THEME_REF = 'pageThemeRef'
export const PAGE_TEMPLATE_REF = 'pageTemplateRef'
export const CONVERSION_METADATA_KEY = 'conversionMetadata'
export const ANONYMOUS_ID_KEY = 'anonymousId'
export const PAGE_EDITOR_POLLING = 300 // 300ms
export const PAGE_EDITOR_SCROLL_SPEED = 10 // in pixels
export const PAGE_EDITOR_SCROLL_BUFFER = 100 // in pixels
export const DRAG_TOUCH_DELAY_DURATION = 250 // in ms
export const PREVIEW_MAX_AGE = 60 * 5 // 5 min
export const PAGE_THEME_SCROLL_SPEED = 300 // in ms
export const SENSITIVE_CONTENT_ACCEPTED = 'sensitiveContentAccepted'
export const SITE_PASSWORD_TOKEN = 'sitePasswordToken'
export const DEFAULT_TIMEZONE = 'Australia/Melbourne'
export const MAX_MESSAGES_SENT = 100
export const MAX_INVOICES_SENT = 3
export const MAX_FORM_RESPONSES = 5
