'use client'
import { getCookie, setCookie } from 'cookies-next'
import { v4 } from 'uuid'

import { ANONYMOUS_ID_KEY } from '@/lib/utils/constants'

export const useAnonymousId = (): string => {
  let anonymousId = getCookie(ANONYMOUS_ID_KEY)

  if (!anonymousId) {
    anonymousId = v4()
    setCookie(ANONYMOUS_ID_KEY, anonymousId)
  }

  return anonymousId as string
}
