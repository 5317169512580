'use client'
import { CallOptions } from '@connectrpc/connect'
import { type Session } from '@supabase/supabase-js'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useIdentifyUser } from '@/lib/hooks/use-identify-user'

import { getSupabaseClient } from '../supabase/supabase-client'
import { useAnonymousId } from './use-anonymous-id'

interface AuthContextType {
  session: Session | null
  token: string | null | undefined
  callOptions: CallOptions
  hasFetched: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function AuthProvider({ children }: { children: ReactNode }) {
  const [hasFetched, setHasFetched] = useState<boolean>(false)
  const [session, setSession] = useState<Session | null>(null)
  const anonymousId = useAnonymousId()
  const { identifyUser } = useIdentifyUser()

  useEffect(() => {
    const supabase = getSupabaseClient()

    const fetchInitialSession = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession()
        setSession(session)
        setHasFetched(true)
        if (session?.user && session.user.email && session.user.id) {
          identifyUser({
            email: session.user.email,
            id: session.user.id,
          })
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching initial session:', error)
        setHasFetched(true)
      }
    }

    fetchInitialSession()

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setTimeout(() => {
        if (event === 'SIGNED_OUT') {
          setSession(null)
        } else if (
          [
            'SIGNED_IN',
            'TOKEN_REFRESHED',
            'USER_UPDATED',
            'PASSWORD_RECOVERY',
          ].includes(event)
        ) {
          setSession(session)
          if (session?.user && session.user.email && session.user.id) {
            identifyUser({
              email: session.user.email,
              id: session.user.id,
            })
          }
        }
      }, 0)
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [identifyUser])

  const headers = useMemo(() => {
    const value: HeadersInit | undefined = {
      'X-Anonymous-Id': anonymousId,
    }
    if (session?.access_token) {
      value['Authorization'] = `Bearer ${session.access_token}`
    }
    return value
  }, [anonymousId, session?.access_token])

  const value = {
    hasFetched,
    session,
    token: session?.access_token,
    callOptions: {
      headers,
    },
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

// Custom hook to use the auth context
export function useToken(): AuthContextType {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useToken must be used within an AuthProvider')
  }
  return context
}
